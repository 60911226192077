<template>
  <div class="container">
    <h4 class="text-center my-4">Картотека профессорско-преподавательского состава</h4>
    <span class="p-input-icon-left">
      <i class="pi pi-search"/>
      <InputText v-model="searchPps" placeholder="Поиск преподавателя"/>
    </span>
    <PrimePreloader v-if="loading"/>
    <div v-else>

      <!-- input ID2 -->
      <input type="hidden" id="alldata">
      <!-- end input ID2 -->

      <DataTable :value="teachersNew.list" showGridlines stripedRows responsiveLayout="scroll"
                 tableStyle="min-width: 50rem">
        <Column header="Фото">
          <template #body="{data}">
            <div v-if="data.photo">
              <img :src="'data:image/png;base64,'+data.photo" width="100" alt="???">
            </div>
            <div v-else>
              Нет фото
            </div>
          </template>
        </Column>
        <Column header="ФИО">
          <template #body="{data}">
            <a @click.prevent="goToUser(data.user_id)" class="text-decoration-none" href="#">
              {{ data.lastname }} {{ data.firstname }} {{ data.middlename }} ({{ data.birth_date }})
            </a>
          </template>
        </Column>
        <Column>
          <template #header>
            Тип договора <br> Должность
          </template>
          <template #body="{data}">
            <p v-for="(contract, index) in data.contracts" :key="index">
              {{ index + 1 }}. {{ contract.teachers_contract_types_name }} {{ contract.teaching_position_name }}
              {{ contract.department }}
            </p>
          </template>
        </Column>
        <Column header="Результаты ККК">
          <template #body="{data}">
            <p style="color: blue; cursor: pointer;" @click="openResultKkk(data.user_id)"
               v-for="(contract, index) in data.contracts" :key="index">
              {{ index + 1 }}. {{ contract.profile_score }} <br>
            </p>
          </template>
        </Column>
        <Column header="Статус">
          <template #body="{data}">
            <p style="color: blue; cursor: pointer;"
               @click="getStatusInfo(data.contract_end_date, data.contract_number)">
              {{data.status}}
            </p>
          </template>
        </Column>
        <Column header="Роли">
          <template #body="{data}">
            <p v-for="(role, index) in data.roles" :key="index">{{ role }}</p>
          </template>
        </Column>
        <Column header="Импорт" class="text-center" :style="{width: '6%'}">
          <template #body="{data}">
            <Button label="ID2" class="p-button-rounded" @click="openImportFormID2Dialog(data.user_id)"/>
          </template>
        </Column>
      </DataTable>

      <Dialog v-model:visible="modal_result_kkk" modal header="Оценка профиля" :style="{ width: '25vw' }">
        <div v-if="kkkResult.type_kkk == 1">
          Количество положительно проголосовавших: <strong>{{ kkkResult.result.count }}</strong>
        </div>
        <div v-else>
          <div v-for="(result, index) in kkkResult.result" :key="index">
            <p>Дата прохождения ККК: {{ result.kkk_interview_time_date }}</p>
            <p>Название дисциплины: {{ result.discipline_name }}</p>
            <p>Язык дисциплины: {{ result.native_name }}</p>
            <p>Оценка по дисциплине: {{ result.kkk_discipline_teaching_score }}</p>

            <br v-if="index !== kkkResult.result.length - 1"><br v-if="index !== kkkResult.result.length - 1">
          </div>
        </div>
      </Dialog>

      <Dialog v-model:visible="modal_status_kkk" modal header="Данные о контракте" :style="{ width: '25vw' }">
        <div>
          <p>Номер контракта: {{contract_number}}</p>
          <p>Дата окончания контракта: {{contract_end_date}}</p>
        </div>
      </Dialog>

      <!-- ImportFromID2 dialog -->
      <Dialog header="Импорт данных из ID2" v-model:visible="displayImportFromID2" :closable="false" modal
              :style="{width: '100%', maxWidth: '600px'}">
        <div class="my-3">
          <div class="row my-2">
            <div class="col-md-5">Данные преподавателя</div>
            <div class="col-md-7 fw-bold">{{ userNameFront }}</div>
          </div>
          <div class="row my-2">
            <div class="col-md-5">Данные из ID2</div>
            <div class="col-md-7 fw-bold">{{ userNameID2 }}</div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeImportFromID2Dialog" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-save"
                  :disabled="disabledSaveImportFromID2"
                  :loading="loadingSaveImportFromID2"
                  @click="saveImportFromID2"
                  autofocus/>
        </template>
      </Dialog>
      <!-- end ImportFromID2 dialog -->

    </div>
    <Paginator :totalRecords="teachersNew.total_count" :rows="15" @page="changePage"></Paginator>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import {
  convertDateToTimestamp,
  convertTimestampToDate,
  convertDateInputToTimestamp
} from "@/utils/helpers/date.helpers"
import {toCapitalize} from "@/utils/helpers/string.helpers";

export default {
  name: 'HRTeachers',
  data() {
    return {
      loading: true,
      searchPps: null,
      modal_result_kkk: false,
      modal_status_kkk: false,
      contract_end_date: null,
      contract_number: null,

      userNameFront: '',
      userNameID2: '',
      displayImportFromID2: false,
      disabledSaveImportFromID2: false,
      loadingSaveImportFromID2: false,
    }
  },
  computed: {
    ...mapState('kkk', ['teachersNew', 'kkkResult'])
  },
  watch: {
    searchPps: async function (value) {
      if (value.length > 2) {
        await this.GET_TEACHERS_NEW({page: 1, limit: 15, teacher_name: value})
      } else if (value.length == 0) {
        await this.GET_TEACHERS_NEW({page: 1, limit: 15, teacher_name: value})
      }
    }
  },
  methods: {
    ...mapActions('kkk', ['GET_TEACHERS_NEW', 'POST_PPS_DATA_BY_USER', 'GET_KKK_RESULT']),
    ...mapMutations('userinfo', ['SET_USER_ID']),
    toCapitalize,
    convertDateToTimestamp,
    convertTimestampToDate,
    convertDateInputToTimestamp,

    async openResultKkk(user_id) {
      this.modal_result_kkk = true;
      await this.GET_KKK_RESULT(user_id);
    },

    getStatusInfo(contract_end_date, contract_number){
      this.contract_end_date = contract_end_date;
      this.contract_number = contract_number;
      this.modal_status_kkk = true;
    },

    importData() {
      let allData = document.getElementById('alldata').value
      allData = allData.replaceAll(`'`, `"`)

      if (allData) {
        const allDataObj = JSON.parse(allData)

        allDataObj.birth_date = this.convertDateToTimestamp(allDataObj.birth_date)
        allDataObj.valid_from = this.convertDateToTimestamp(allDataObj.valid_from)
        allDataObj.valid_to = this.convertDateToTimestamp(allDataObj.valid_to)

        allDataObj.last_name = this.toCapitalize(allDataObj.last_name)
        allDataObj.first_name = this.toCapitalize(allDataObj.first_name)
        allDataObj.middle_name = this.toCapitalize(allDataObj.middle_name)
        allDataObj.last_name_en = this.toCapitalize(allDataObj.last_name_en)
        allDataObj.first_name_en = this.toCapitalize(allDataObj.first_name_en)

        return allDataObj
      }
      return null
    },

    openImportFormID2Dialog(userId) {

      const user = this.teachersNew.list.find(i => i.user_id === userId)
      this.userNameFront = `${user?.lastname} ${user?.firstname}`

      const dataID2 = this.importData()
      if (dataID2) {
        this.formPpsData = {
          user_id: user.user_id,
          firstname: dataID2.first_name,
          lastname: dataID2.last_name,
          middlename: dataID2.middle_name,
          birth_date: dataID2.birth_date,
          birth_place: dataID2.birth_place,
          sex: dataID2.sex === 'M' ? 1 : 2,
          doc_number: dataID2.document_number,
          doc_iin: dataID2.iin,
          doc_issued: dataID2.issuer,
          doc_date_start: dataID2.valid_from,
          doc_date_end: dataID2.valid_to,
          doc_type: 1,
          photo: dataID2.photo
        }

        this.userNameID2 = `${dataID2?.last_name} ${dataID2?.first_name}`
        this.disabledSaveImportFromID2 = false
      } else {
        this.userNameID2 = `Данные из ID2 не импортированы`
        this.disabledSaveImportFromID2 = true
      }

      this.displayImportFromID2 = true
    },
    closeImportFromID2Dialog() {
      this.displayImportFromID2 = false
    },
    async saveImportFromID2() {
      this.loadingSaveImportFromID2 = true

      const res = await this.POST_PPS_DATA_BY_USER(this.formPpsData)
      if (res) {
        this.closeImportFromID2Dialog()
        this.$message({title: 'Данные успешно сохранены'})
        await this.GET_TEACHERS_NEW({page: 1, limit: 15})
      }

      this.loadingSaveImportFromID2 = false
    },

    goToUser(userId) {
      this.SET_USER_ID(userId)
      this.$router.push('/user')
    },

    async changePage(event) {
      this.loading = true
      await this.GET_TEACHERS_NEW({page: event.page + 1, limit: 15})
      this.loading = false
    }
  },
  async mounted() {
    await this.GET_TEACHERS_NEW({page: 1, limit: 15})
    this.loading = false
  },
}

</script>

<style scoped>
</style>
